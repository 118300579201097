import React from 'react'
import { ActionProps, RequestActionButton } from '.'

export function AcceptRequestAction({ id }: ActionProps) {
  return (
    <RequestActionButton
      functionName="acceptRequest"
      args={[id]}
    >
      Accept
    </RequestActionButton>
  )
}
