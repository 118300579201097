// Forked from https://github.com/MetaMask/eth-sig-util/blob/main/src/encryption.ts#L22
import * as ethUtil from "ethereumjs-util"
import * as nacl from "tweetnacl"
import * as naclUtil from "tweetnacl-util"

function isNullish(value: any): boolean {
  return value === null || value === undefined
}

function rawEncrypt(
  publicKey: string,
  data: string,
  secretKey: Uint8Array,
  nonce: Uint8Array
): Uint8Array {
  if (isNullish(publicKey)) {
    throw new Error("Missing publicKey parameter")
  } else if (isNullish(data)) {
    throw new Error("Missing data parameter")
  }

  // assemble encryption parameters - from string to UInt8
  let pubKeyUInt8Array
  try {
    pubKeyUInt8Array = naclUtil.decodeBase64(publicKey)
  } catch (err) {
    throw new Error("Bad public key")
  }

  const msgParamsUInt8Array = naclUtil.decodeUTF8(data)

  // encrypt
  const encryptedMessage = nacl.box(
    msgParamsUInt8Array,
    nonce,
    pubKeyUInt8Array,
    secretKey
  )

  return encryptedMessage
}

// The function returns the encrypted data and a secret. The secretWitness can be revealed in
// future with the original data to verify the correctness of the encrypted data.
// encryptedData can be made public but the secretWitness should only be disclosed to an
// authorized party to verify.
export function verifiablyEncrypt(
  publicKey: string,
  data: string
): {
  encryptedData: string
  secretWitness: string
} {
  const ephemeralKeyPair = nacl.box.keyPair()
  const nonce = nacl.randomBytes(nacl.box.nonceLength)

  const encryptedMessage = rawEncrypt(
    publicKey,
    data,
    ephemeralKeyPair.secretKey,
    nonce
  )

  // handle encrypted data
  const rawEncryptedData = {
    version: "x25519-xsalsa20-poly1305",
    nonce: naclUtil.encodeBase64(nonce),
    ephemPublicKey: naclUtil.encodeBase64(ephemeralKeyPair.publicKey),
    ciphertext: naclUtil.encodeBase64(encryptedMessage),
  }

  return {
    encryptedData: ethUtil.bufferToHex(
      Buffer.from(JSON.stringify(rawEncryptedData))
    ),
    secretWitness: ethUtil.bufferToHex(
      ethUtil.toBuffer(ephemeralKeyPair.secretKey)
    ),
  }
}

// Verifies the encryption was done correctly based on the secretWitness and original
// data. Throws error on invalid/ incorrect encryption.
export function verifyEncryption(
  publicKey: string,
  data: string,
  encryptedData: string,
  secretWitness: string
): void {
  const rawEncryptedData = JSON.parse(
    ethUtil.toBuffer(encryptedData).toString()
  )
  if (isNullish(rawEncryptedData.ciphertext)) {
    throw new Error("Invalid ciphertext")
  }

  const secretKey = new Uint8Array(ethUtil.toBuffer(secretWitness))

  const expectedPublic = naclUtil.encodeBase64(
    nacl.box.keyPair.fromSecretKey(secretKey).publicKey
  )
  if (expectedPublic !== rawEncryptedData.ephemPublicKey) {
    throw new Error("Witness does not match encrypted data")
  }

  const expectedEncryptedMessage = naclUtil.encodeBase64(
    rawEncrypt(
      publicKey,
      data,
      secretKey,
      naclUtil.decodeBase64(rawEncryptedData.nonce)
    )
  )

  if (expectedEncryptedMessage !== rawEncryptedData.ciphertext) {
    throw new Error("Invalid encryption")
  }
}
