import React from 'react'
import Link from 'next/link'
import { useFactoryContractRead } from '../hooks'
import { TokenContext } from '../context'
import { Th, Td } from '.'

// TODO: Remove duplication with ProviderRedeemers
export function TokenRedeemers() {
  const token = React.useContext(TokenContext)
  const { data, error, isError, isLoading } = useFactoryContractRead({
    functionName: 'getRedeemersForToken',
    args: token?.address,
    enabled: !!token,
  })

  if (isLoading) {
    return (
      <p>Loading...</p>
    )
  }

  if (isError) {
    return (
      <>
        <h1>Error Occurred</h1>
        <p>{error?.message}</p>
      </>
    )
  }

  return (
    <>
      <h1>Phygital Redeemer instances for {token?.type} Token <code>{token?.address.slice(0, 7)}</code></h1>
      <table style={{borderCollapse: 'collapse', width: '100%'}}>
        <thead>
          <tr>
            <Th>Redeemer Address</Th>
            <Th>Physical Item Provider</Th>
            <Th>Token Type</Th>
          </tr>
        </thead>
        <tbody>
          {data
            ?.filter(redeem => redeem.tokenType == (token?.type == 'ERC-721' ? 0 : 1))
            .map(redeem => {
              return (
                <tr key={redeem.redeemer}>
                  <Td><Link href={`/user/${redeem.tokenType == '0' ? 'ERC-721'  : 'ERC-1155'}/redeemer/${redeem.redeemer}`}>{redeem.redeemer}</Link></Td>
                  <Td>{redeem.provider}</Td>
                  <Td>{redeem.tokenType == 0 ? 'ERC-721' : 'ERC-1155'}</Td>
                </tr>
              )
            })}
        </tbody>
      </table>
    </>
  )
}
