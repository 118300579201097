import React from 'react'
import { ActionProps, RequestActionButton } from '.'

export function ExpireRequestAction({ id }: ActionProps) {
  return (
    <RequestActionButton
      functionName="expireRequest"
      args={[id]}
    >
      Expire
    </RequestActionButton>
  )
}
