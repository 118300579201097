import React from 'react'
import { useRouter } from 'next/router'
import { IToken } from '../types'

export function useGetToken() {
  const [token, setToken] = React.useState<IToken | null>(null)

  const router = useRouter()
  const { token: tokenAddress, tokenType } = router.query

  React.useEffect(() => {
    if (!tokenAddress || !tokenType) {
      return
    }

    setToken({
      address: tokenAddress as string,
      type: tokenType as IToken["type"],
    })
  }, [tokenAddress, tokenType])

  return { token }
}
