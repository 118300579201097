import React from 'react'
import { useAccount } from 'wagmi'
import { useContractEvent, useSingleContractReads } from '../hooks'
import { RedeemerContext } from '../context'
import { IUserType } from '../types'
import { OpenRequest, Th, Request } from '.'

export function UserRequests() {
  const { address } = useAccount()
  const { redeemer, contractConfig } = React.useContext(RedeemerContext)

  const { data, error, isError, isLoading, refetch } = useSingleContractReads({
    ...contractConfig,
    contracts: [
      { functionName: 'getRequests' },
      { functionName: 'token' },
      { functionName: 'providerPublicKey' },
    ],
  })

  useContractEvent({
    ...contractConfig,
    eventName: 'RequestStatusUpdate',
    listener: () => refetch(),
  })

  if (!redeemer || isLoading) {
    return (
      <p>Loading...</p>
    )
  }

  if (isError) {
    return (
      <>
        <h1>Error Occurred</h1>
        <p>{error?.message}</p>
      </>
    )
  }

  const userRequests = data?.[0]

  return (
    <>
      {userRequests && userRequests.length > 0 && (
        <>
          <h1>User Requests for Redeemer <code>{redeemer.address.substring(0, 7)}</code></h1>
          <p>Phygital Redeemer instance address: {redeemer.address}</p>
          {/* <p>Physical item provider's address: {}</p> */}
          <p>Phygital token address: {data?.[1]}</p>
          <p>Phygital token type: {redeemer.tokenType}</p>
          <table style={{borderCollapse: 'collapse', width: '100%'}}>
            <thead>
              <tr>
                <Th>Request ID</Th>
                <Th>Owner</Th>
                <Th>Token ID(s)</Th>
                <Th>Status</Th>
                <Th>Actions</Th>
              </tr>
            </thead>
            <tbody>
              {data?.[0]
                .map((request, id) => {
                  return {
                    id,
                    ...request,
                  }
                })
                .filter(request => request.owner == address)
                .map(request => {
                  return (
                    <Request
                      key={request.id}
                      id={request.id}
                      owner={request.owner}
                      tokenIds={request.tokenIds}
                      userType={IUserType.User}
                    />
                  )
              })}
            </tbody>
          </table>
        </>
      )}
      <OpenRequest providerPublicKey={data?.[2] as unknown as string} />
    </>
  )
}
