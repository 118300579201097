import React from 'react'
import Link from 'next/link'

export function NavBar() {
  return (
    <>
      <h1 style={{fontSize: '20px'}}>Phygital Redemptions</h1>
      <ul>
        <li>
          <Link href="/owner">
            <a>Owner</a>
          </Link>
        </li>
        <li>
          <Link href="/provider">
            <a>Provider</a>
          </Link>
        </li>
        <li>
          <Link href="/user">
            <a>User</a>
          </Link>
        </li>
        <li style={{marginTop: '10px'}}>
          <Link href="/public-key">
            <a>Public Key</a>
          </Link>
        </li>
        <li>
          <Link href="/encrypt">
            <a>Encrypt</a>
          </Link>
        </li>
        <li>
          <Link href="/decrypt">
            <a>Decrypt</a>
          </Link>
        </li>
        <li>
          <Link href="/verify">
            <a>Verify</a>
          </Link>
        </li>
      </ul>
    </>
  )
}
