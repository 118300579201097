import React from 'react'
import { useRouter } from 'next/router'
import { IRedeemer, IToken } from '../types'
import { abi } from '../../constants'

export function useGetRedeemer() {
  const [redeemer, setRedeemer] = React.useState<IRedeemer | null>(null)

  const router = useRouter()
  const { redeemer: redeemerAddress, tokenType } = router.query

  React.useEffect(() => {
    if (!redeemerAddress || !tokenType) {
      return
    }

    setRedeemer({
      address: redeemerAddress as string,
      tokenType: tokenType as IToken["type"],
    })
  }, [redeemerAddress, tokenType])

  const contractConfig = {
    addressOrName: redeemer?.address || '',
    contractInterface: redeemer?.tokenType == 'ERC-721' ? abi.redeemer721 : abi.redeemer1155 as any[],
    enabled: !!redeemer,
  }

  return { redeemer, contractConfig }
}
