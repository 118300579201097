import React from 'react'
import { useAccount } from 'wagmi'
import { useFactoryContractWrite, useGetPublicKey } from '../hooks'
import { ADDRESS_ZERO } from '../../constants'
import {
  TransactionResult,
  PublicKeyButton,
  Input,
  Select,
} from '.'

export function CreateRedeemer() {
  const { address } = useAccount()
  const { publicKey, getPublicKey } = useGetPublicKey()

  const [token, setToken] = React.useState('')
  const [provider, setProvider] = React.useState(address)
  const [providerPublicKey, setProviderPublicKey] = React.useState(publicKey || '')
  const [whitelist, setWhitelist] = React.useState('')
  const [tokenType, setTokenType] = React.useState(0)
  const [tokenName, setTokenName] = React.useState('')
  const [tokenSymbol, setTokenSymbol] = React.useState('')
  const [tokenURI, setTokenURI] = React.useState('')

  const {
    write,
    isLoading,
    ...contractWriteResult
  } = useFactoryContractWrite({
    functionName: 'createRedeemer',
    args: [
      token,
      provider,
      providerPublicKey,
      tokenType,
      whitelist || ADDRESS_ZERO,
      tokenName,
      tokenSymbol,
      tokenURI,
    ],
    enabled: !!token && !!tokenName && !!tokenSymbol && !!tokenURI && (tokenType == 0 || tokenType == 1),
  })

  React.useEffect(() => {
    setProviderPublicKey(publicKey || '')
  }, [publicKey])

  return (
    <>
      <h1>Deploy new Phygital Redeemer instance</h1>
      {publicKey == null ? (
        <PublicKeyButton getPublicKey={getPublicKey} />
      ) : (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              write?.()
            }}
          >
            <label>Phygital Token</label>
            <Input
              aria-label="Phygital Token"
              onChange={(e) => setToken(e.target.value)}
              placeholder="0xA0Cf…251e"
              value={token}
              required
            />
            <label>Provider Address</label>
            <Input
              aria-label="Provider Address"
              onChange={(e) => setProvider(e.target.value)}
              placeholder="0xa11C…ef26"
              value={provider}
              required
            />
            <label>Provider Public Key</label>
            <Input
              aria-label="Provider Public Key"
              onChange={(e) => setProviderPublicKey(e.target.value)}
              placeholder="0xb0b4…f9z9"
              value={providerPublicKey}
              required
            />
            <label>Open Request Hooks contract, e.g., Token ID Whitelist (optional)</label>
            <Input
              aria-label="Token ID Whitelist Address"
              onChange={(e) => setWhitelist(e.target.value)}
              placeholder="0xCdfa…47d3"
              value={whitelist}
            />
            <label>SBT Name</label>
            <Input
              aria-label="SBT Name"
              onChange={(e) => setTokenName(e.target.value)}
              placeholder="Redeemed Phygital"
              value={tokenName}
              required
            />
            <label>SBT Symbol</label>
            <Input
              aria-label="SBT Symbol"
              onChange={(e) => setTokenSymbol(e.target.value)}
              placeholder="RP"
              value={tokenSymbol}
              required
            />
            <label>SBT tokenURI</label>
            <Input
              aria-label="SBT tokenURI"
              onChange={(e) => setTokenURI(e.target.value)}
              placeholder="ipfs://0x64EC88CA00B268E5BA1A35678A1B5316D212F4F366B2477232534A8AECA37F3C"
              value={tokenURI}
              required
            />
            <Select
              aria-label="Token Type"
              onChange={(e) => setTokenType(e.target.value == '0' ? 0 : 1)}
              value={tokenType}
            >
              <option value="0">ERC-721</option>
              <option value="1">ERC-1155</option>
            </Select>
            <button disabled={!write || isLoading || !token || !tokenName || !tokenSymbol || !tokenURI}>
              {isLoading ? 'Deploying...' : 'Deploy'}
            </button>
            <TransactionResult {...contractWriteResult} />
          </form>
        </>
      )}
    </>
  )
}
