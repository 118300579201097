import React from 'react'
import { useContractRead } from 'wagmi'
import { abi, addresses } from '../../constants'

export { useContractRead } from 'wagmi'

type UseFactoryContractReadArgs = Omit<Parameters<typeof useContractRead>[0], 'addressOrName'|'contractInterface'>

export function useFactoryContractRead(args: UseFactoryContractReadArgs) {
  return useContractRead({
    addressOrName: addresses.factory,
    contractInterface: abi.factory,
    ...args
  })
}
