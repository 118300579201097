import React from 'react'

const selectStyle: React.CSSProperties = {
  display: 'block',
  padding: '4px 4px',
  margin: '8px 0',
  borderRadius: '4px',
  boxSizing: 'border-box',
}

type SelectProps = React.SelectHTMLAttributes<HTMLSelectElement>

export function Select(props: SelectProps) {
  return (
    <select style={selectStyle} {...props} />
  )
}
