import React from 'react'
import { EncryptedRequestAction, ActionProps } from '.'

export function FulfillRequestAction(args: ActionProps) {
  return (
    <EncryptedRequestAction
      args={[args.id]}
      publicKey={args.request.ownerPublicKey}
      functionName="fulfillRequest"
      receiptFilename={`request_${args.id}_fulfillment_receipt`}
      labelText="Shipment Tracking Information"
      ariaLabel="Shipment Tracking Information"
      inputPlaceholder="e.g., DHL tracking number"
      buttonText="Fulfill"
      buttonSubmittingText="Fulfilling..."
      {...args}
    />
  )
}
