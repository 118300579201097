import React from 'react'
import { useContractEvent } from 'wagmi'
import { abi, addresses } from '../../constants'

export { useContractEvent } from 'wagmi'

type UseFactoryContractEventArgs = Omit<Parameters<typeof useContractEvent>[0], 'addressOrName'|'contractInterface'>

export function useFactoryContractEvent(args: UseFactoryContractEventArgs) {
  return useContractEvent({
    addressOrName: addresses.factory,
    contractInterface: abi.factory,
    ...args
  })
}
