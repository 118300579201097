import React from 'react'
import { EncryptedRequestAction, ActionProps } from '.'

export function RejectRequestAction(args: ActionProps) {
  return (
    <EncryptedRequestAction
      args={[args.id]}
      publicKey={args.request.ownerPublicKey}
      functionName="rejectRequest"
      receiptFilename={`request_${args.id}_rejection_receipt`}
      labelText="Rejection Reason"
      ariaLabel="Rejection Reason"
      inputPlaceholder="e.g., shipping address missing country"
      buttonText="Reject"
      buttonSubmittingText="Rejecting..."
      {...args}
    />
  )
}
