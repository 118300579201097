import React from "react";
import { IUserType } from "../types";
import { useContractEvent, useSingleContractReads } from "../hooks";
import { RedeemerContext } from "../context";
import { Th, Request } from ".";

export function ProviderRequests() {
  const { redeemer, contractConfig } = React.useContext(RedeemerContext);

  const { data, error, isError, isLoading, refetch } = useSingleContractReads({
    ...contractConfig,
    contracts: [{ functionName: "getRequests" }, { functionName: "token" }],
  });

  useContractEvent({
    ...contractConfig,
    eventName: "RequestStatusUpdate",
    listener: () => refetch(),
  });

  if (!redeemer || isLoading) {
    return <p>Loading...</p>;
  }

  if (isError) {
    return (
      <>
        <h1>Error Occurred</h1>
        <p>{error?.message}</p>
      </>
    );
  }
  return (
    <>
      <h1>
        Provider Requests for Redeemer{" "}
        <code>{redeemer.address.substring(0, 7)}</code>
      </h1>
      <p>Phygital Redeemer instance address: {redeemer.address}</p>
      <p>Phygital token address: {data?.[1]}</p>
      <p>Phygital token type: {redeemer.tokenType}</p>
      <table style={{ borderCollapse: "collapse", width: "100%" }}>
        <thead>
          <tr>
            <Th>Request ID</Th>
            <Th>Owner</Th>
            <Th>Token ID(s)</Th>
            <Th>Status</Th>
            <Th>Actions</Th>
          </tr>
        </thead>
        <tbody>
          {data?.[0]?.map((request, id) => {
            return (
              <Request
                key={id}
                id={id}
                owner={request.owner}
                tokenIds={request.tokenIds}
                userType={IUserType.Provider}
              />
            );
          })}
        </tbody>
      </table>
    </>
  );
}
