import React from 'react'
import { useAccount } from 'wagmi'
import type { ActionProps } from './RequestActions'

type ViewProviderMessageActionProps = ActionProps & {
  messageType: string
}

export function ViewProviderMessageAction({ requestState, messageType }: ViewProviderMessageActionProps) {
  const [message, setMessage] = React.useState('')
  const [error, setError] = React.useState<Error | null>(null)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const { address } = useAccount()

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setIsLoading(true)
    setError(null)
    try {
      setMessage(await window.ethereum?.request({
        // @ts-ignore
        method: 'eth_decrypt',
        // @ts-ignore
        params: [requestState.providerMessage, address],
      }) as unknown as string)
    } catch (error: unknown) {
      setIsLoading(false)
      setError(error as Error)
    }
  }

  return (
    <>
      {(message && !error) ? (
        <p>{messageType}: <b>{message}</b></p>
      ) : (
        <button
          style={{cursor: 'pointer'}}
          disabled={isLoading}
          onClick={handleClick}
        >
          {isLoading ? 'Decrypting...' : `View ${messageType}`}
        </button> 
      )}
      {error && (
        <p>Error: <>{error.message}</></p>
      )}
    </>
  )
}
