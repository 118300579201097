import React from 'react'
import { BigNumber } from 'ethers'
import { formatStatusCode } from '../utils/formatting'
import { useSingleContractReads, useContractEvent } from '../hooks'
import { RedeemerContext } from '../context'
import { Td, RequestActions } from '.'
import type { IRequest, IRequestState, IUserType } from '../types'

type RequestProps = {
  id: number
  owner: string
  tokenIds: BigNumber[]
  userType: IUserType
}

export function Request({ id, owner, tokenIds, userType }: RequestProps) {
  const { contractConfig } = React.useContext(RedeemerContext)

  // TODO: handle error
  const { data, error, isError, isLoading, refetch } = useSingleContractReads({
    ...contractConfig,
    contracts: [
      {
        functionName: 'getRequestState',
        args: [id],
      },
      {
        functionName: 'getRequest',
        args: [id],
      }
    ],
  })

  useContractEvent({
    ...contractConfig,
    eventName: 'RequestStatusUpdate',
    listener: () => refetch(),
  })

  if (!data?.every(value => !!value)) {
    return null
  }

  return (
    <tr key={id}>
        <Td>{id}</Td>
        <Td>{owner}</Td>
        <Td>{tokenIds.map((tokenId: BigNumber) => tokenId.toNumber()).join(', ')}</Td>
        <Td>{isLoading ? 'Loading...' : formatStatusCode(data?.[0].status)}</Td>
        <Td>
          <RequestActions
            id={id}
            requestState={data?.[0] as unknown as IRequestState}
            request={data?.[1] as unknown as IRequest}
            userType={userType}
          />
        </Td>
    </tr>
  )
}
