import React from 'react'
import { useContractWrite } from '../hooks'
import { TransactionResult, Input } from '.'
import { RedeemerContext } from '../context'

type RequestActionButtonProps = {
  functionName: string
  args: any[]
  children: React.ReactNode
}

export function RequestActionButton({ functionName, args, children }: RequestActionButtonProps) {
  const { contractConfig } = React.useContext(RedeemerContext)

  const {
    write,
    isLoading,
    ...contractWriteResult
  } = useContractWrite({
    ...contractConfig,
    functionName,
    args,
  })

  return (
    <>
      <button
        style={{cursor: 'pointer'}}
        disabled={!write || isLoading}
        onClick={() => write?.()}
      >
        {isLoading ? 'Sending...' : <>{children}</>}
      </button>
      <TransactionResult {...contractWriteResult} />
    </>
  )
}
