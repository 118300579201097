export type IRequestCommon = {
  owner: string
  ownerPublicKey: string
  tokenIds: number[]
  shippingAddress: string
}

export type IRequest721 = IRequestCommon
export type IRequest1155 = IRequestCommon & {
  amounts: number[]
}

export type IRequest = IRequest721 | IRequest1155

export type IRedeemer = {
  address: string
  tokenType: 'ERC-721' | 'ERC-1155'
}

export type IRequestState = {
  status: number
  expiry: number
  providerMessage: string
}

export type IToken = {
  address: string
  type: 'ERC-721' | 'ERC-1155'
}

export enum IUserType {
  User,
  Provider
}
