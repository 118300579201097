import React from "react";
import {
  usePrepareContractWrite,
  useContractWrite as useContractWriteWagmi,
  useWaitForTransaction,
} from "wagmi";
import { abi, addresses } from "../../constants";

type UseContractWriteWagmiArgs = Parameters<typeof useContractWriteWagmi>[0];

type UseContractWriteArgs = Omit<
  Parameters<typeof usePrepareContractWrite>[0],
  "overrides" | "onError"
> & {
  onWriteSuccess?: UseContractWriteWagmiArgs["onSuccess"];
};

export function useContractWrite(args: UseContractWriteArgs) {
  const {
    config,
    error: prepareError,
    isError: isPrepareError,
  } = usePrepareContractWrite({
    ...args,
  });

  const { data, error, isError, write } = useContractWriteWagmi({
    ...config,
    onSuccess: args.onWriteSuccess,
  });

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  });

  return {
    write,
    data,
    isLoading,
    isSuccess,
    error,
    isError,
    prepareError,
    isPrepareError,
  };
}

type UseFactoryContractWriteArgs = Omit<
  UseContractWriteArgs,
  "addressOrName" | "contractInterface"
>;

export function useFactoryContractWrite(args: UseFactoryContractWriteArgs) {
  return useContractWrite({
    addressOrName: addresses.factory,
    contractInterface: abi.factory,
    ...args,
  });
}
