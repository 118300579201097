import React from 'react'
import { BigNumber } from 'ethers'
import { IRequest, IRequestState, IUserType } from '../types'
import {
  AcceptRequestAction,
  RejectRequestAction,
  FulfillRequestAction,
  CloseRequestAction,
  ExpireRequestAction,
  ViewShippingAddressAction,
  ViewProviderMessageAction,
} from '.'

export type ActionProps = Omit<RequestActionsProps, 'status'|'userType'> & {
  key: number
}

type Action = ({ key, id, request }: ActionProps) => JSX.Element

type ActionByStatus = Record<number, Action[] | null>

const actionsByStatusForProvider: ActionByStatus = {
  0: null,
  1: [AcceptRequestAction, RejectRequestAction, ViewShippingAddressAction],
  2: null,
  3: [FulfillRequestAction],
  4: null,
  5: null,
  6: null,
}

function ViewTrackingInfoAction(args: ActionProps) {
  return (
    <ViewProviderMessageAction
      messageType="Tracking Info"
      {...args}
    />
  )
}

function ViewRejectionReasonAction(args: ActionProps) {
  return (
    <ViewProviderMessageAction
      messageType="Rejection Reason"
      {...args}
    />
  )
}

function ExpirationAction(args: ActionProps) {
  const currentDate = new Date()
  const expirationDate = new Date(BigNumber.from(args.requestState.expiry).toNumber() * 1000)
  if (currentDate > expirationDate) {
    return (
      <ExpireRequestAction {...args} />
    )
  }

  return (
    <p>Request expires at: <b>{expirationDate.toString()}</b></p>
  )
}

const actionsByStatusForUser: ActionByStatus = {
  0: null,
  1: [CloseRequestAction],
  2: null,
  3: [ExpirationAction],
  4: [ViewRejectionReasonAction],
  5: [ViewTrackingInfoAction],
  6: null,
}

const actionsByUserType: { [key in IUserType]: ActionByStatus } = {
  [IUserType.Provider]: actionsByStatusForProvider,
  [IUserType.User]: actionsByStatusForUser, 
}

type RequestActionsProps = {
  id: number
  requestState: IRequestState
  request: IRequest
  userType: IUserType
}

export function RequestActions({ id, requestState, request, userType }: RequestActionsProps) {
  return (
    <>
      {actionsByUserType[userType][requestState.status]
        ?.map((Element: Action, index) => {
          return (
            <Element
              key={index}
              id={id}
              requestState={requestState}
              request={request}
            />
          )
        })}
    </>
  )
}
