import React from 'react'
import Link from 'next/link'
import { useAccount } from 'wagmi'
import { useFactoryContractRead, useFactoryContractEvent } from '../hooks'
import { Th, Td } from '.'

export function ProviderRedeemers() {
  const { address } = useAccount()

  const { data, error, isError, isLoading, refetch } = useFactoryContractRead({
    functionName: 'getRedeemersForProvider',
    args: address,
  })

  useFactoryContractEvent({
    eventName: 'RedeemerCreate',
    listener: () => refetch(),
  })

  if (isLoading) {
    return (
      <p>Loading...</p>
    )
  }

  if (isError) {
    return (
      <>
        <h1>Error Occurred</h1>
        <p>{error?.message}</p>
      </>
    )
  }

  return (
    <>
      <h1>Provider Phygital Redeemer instances</h1>
      <table style={{borderCollapse: 'collapse', width: '100%'}}>
        <thead>
          <tr>
            <Th>Redeemer Address</Th>
            <Th>Phygital Token Address</Th>
            <Th>Token Type</Th>
          </tr>
        </thead>
        <tbody>
          {data?.map(redeem => {
            return (
              <tr key={redeem.redeemer}>
                <Td><Link href={`/provider/${redeem.tokenType == '0' ? 'ERC-721' : 'ERC-1155'}/${redeem.redeemer}`}>{redeem.redeemer}</Link></Td>
                <Td>{redeem.token}</Td>
                <Td>{redeem.tokenType == 0 ? 'ERC-721' : 'ERC-1155'}</Td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}
