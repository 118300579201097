import React from 'react'
import { useAccount } from 'wagmi'
import { utils } from 'ethers'
const { hexlify } = utils

export function useGetPublicKey() {
  const [publicKey, setPublicKey] = React.useState<string | null>(null)
  const { address: accountAddress } = useAccount()

  const localStorageKey = `publicKey-${accountAddress}`

  React.useEffect(() => {
    const storedPublicKey = localStorage.getItem(localStorageKey)
    if (storedPublicKey) {
      setPublicKey(JSON.parse(storedPublicKey))
    }
  }, [])

  React.useEffect(() => {
    const storedPublicKey = localStorage.getItem(localStorageKey)
    if (!storedPublicKey) {
      setPublicKey(null)
    } else {
      setPublicKey(JSON.parse(storedPublicKey))
    }
  }, [accountAddress])

  React.useEffect(() => {
    if (publicKey == null) {
      return
    }

    localStorage.setItem(localStorageKey, JSON.stringify(publicKey))
  }, [publicKey])

  const getPublicKey = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault()
    const publicKeyEncoded = await window.ethereum?.request({
      // @ts-ignore
      method: 'eth_getEncryptionPublicKey',
      // @ts-ignore
      params: [accountAddress],
    }) as unknown as string
    setPublicKey(hexlify(Buffer.from(publicKeyEncoded, 'base64')))
  }

  return { publicKey, getPublicKey }
}
