import React from 'react'
import { IRedeemer } from '../types'

type IContractConfig = {
  addressOrName: string
  contractInterface: any[]
  enabled?: boolean
}

export type IRedeemerContext = {
  redeemer: IRedeemer | null
  contractConfig: IContractConfig
}

export const RedeemerContext = React.createContext<IRedeemerContext>({
  redeemer: null,
  contractConfig: {
    addressOrName: '',
    contractInterface: [],
  } 
})
