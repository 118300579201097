import React from 'react'
import { ActionProps, RequestActionButton } from '.'

export function CloseRequestAction({ id }: ActionProps) {
  return (
    <RequestActionButton
      functionName="closeRequest"
      args={[id]}
    >
      Close
    </RequestActionButton>
  )
}
