import PhygitalRedeemerFactoryAbi from './abi/PhygitalRedeemerFactory.json'
import PhygitalRedeemer721Abi from './abi/PhygitalRedeemer721.json'
import PhygitalRedeemer1155Abi from './abi/PhygitalRedeemer1155.json'

export const abi = {
  factory: PhygitalRedeemerFactoryAbi,
  redeemer721: PhygitalRedeemer721Abi,
  redeemer1155: PhygitalRedeemer1155Abi,
}

export const addresses = {
  factory: process.env.NEXT_PUBLIC_PHYGITAL_REDEEMER_FACTORY as string,
}

export const orderedStatuses = [
  'NULL',
  'OPENED',
  'CLOSED',
  'ACCEPTED',
  'REJECTED',
  'FULFILLED',
  'EXPIRED',
]

export const ADDRESS_ZERO = '0x0000000000000000000000000000000000000000'
