import React from 'react'
import { useAccount } from 'wagmi'
import { Account, Connect, NetworkSwitcher, NavBar } from '../components'
import { useIsMounted } from '../hooks'

type LayoutProps = {
  children?: React.ReactNode
}

export function Layout({ children }: LayoutProps) {
  const isMounted = useIsMounted()
  const { isConnected } = useAccount()

  return (
    <>
      {isMounted && (
        <>
          <Connect />
          {isConnected && (
            <>
              <Account />
              <NetworkSwitcher />
              <NavBar />
              {children || null}
            </>
          )}
        </>
      )}
    </>
  )
}
