import React from 'react'
import { useFactoryContractEvent, useFactoryContractRead } from '../hooks'
import { Th, Td } from '.'

export function Redeemers() {
  const { data, error, isError, isLoading, refetch } = useFactoryContractRead({
    functionName: 'getRedeemers',
    watch: true,
  })

  useFactoryContractEvent({
    eventName: 'RedeemerCreate',
    listener: () => refetch()
  })

  if (isLoading) {
    return (
      <p>Loading...</p>
    )
  }

  if (isError) {
    return (
      <>
        <h1>Error Occurred</h1>
        <p>{error?.message}</p>
      </>
    )
  }

  return (
    <>
      <h1>Redeemer instances</h1>
      <div style={{ overflowX: 'auto' }}>
        <table style={{borderCollapse: 'collapse', width: '100%'}}>
          <thead>
            <tr>
              <Th>Redeemer Address</Th>
              <Th>Phygital Token Address</Th>
              <Th>Open Request Hook Contract Address</Th>
              <Th>Provider Address</Th>
              <Th>Token Type</Th>
            </tr>
          </thead>
          <tbody>
            {data?.map(redeem => {
              return (
                <tr key={redeem.redeemer}>
                  <Td>{redeem.redeemer}</Td>
                  <Td>{redeem.token}</Td>
                  <Td>{redeem.openRequestHooks}</Td>
                  <Td>{redeem.provider}</Td>
                  <Td>{redeem.tokenType == 0 ? 'ERC-721' : 'ERC-1155'}</Td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}
