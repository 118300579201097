import React from 'react'
import debounce from 'lodash.debounce'
import { utils } from 'ethers'
import { useContractWrite } from '../hooks'
import { verifiablyEncrypt } from '../utils/encryption'
import { downloadObjectAsFile } from '../utils/files'
import { TransactionResult, Input } from '.'
import { RedeemerContext } from '../context'
const { base64 } = utils

type EncryptedRequestProps = {
  args: any[]
  publicKey: string
  functionName: string
  receiptFilename: string
  labelText: string
  ariaLabel: string
  inputPlaceholder: string
  buttonText: string
  buttonSubmittingText: string
  children?: React.ReactNode
}

type EncryptedData = {
  encryptedData: string
  secretWitness: string
}

export function EncryptedRequestAction({
  args,
  publicKey,
  functionName,
  receiptFilename,
  labelText,
  ariaLabel,
  inputPlaceholder,
  buttonText,
  buttonSubmittingText,
  children,
}: EncryptedRequestProps) {
  const [message, setMessage] = React.useState('')
  const [encryptedData, setEncrypedData] = React.useState<EncryptedData | null>(null)

  const { contractConfig } = React.useContext(RedeemerContext)

  const publicKeyEncoded = base64.encode(publicKey)

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newMessage = event.target.value
    setMessage(newMessage)
    setEncrypedData(verifiablyEncrypt(publicKeyEncoded, newMessage))
  }

  const debouncedChangeHandler = React.useMemo(
    () => debounce(changeHandler, 500)
  , [])

  const {
    write,
    isLoading,
    ...contractWriteResult
  } = useContractWrite({
    ...contractConfig,
    functionName,
    args: [
      ...args,
      encryptedData?.encryptedData,
    ],
    onWriteSuccess(data) {
      downloadObjectAsFile({
        txHash: data.hash,
        publicKey: publicKeyEncoded,
        message,
        ciphertext: encryptedData?.encryptedData,
        witness: encryptedData?.secretWitness,
      }, receiptFilename)
    },
    enabled: !!message,
  })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    write?.()
  }

  return (
    <form
      style={{marginTop: '25px'}}
      onSubmit={handleSubmit}
    >
      <label style={{marginRight: '5px'}}>{labelText}</label>
      <Input
        aria-label={ariaLabel}
        onChange={debouncedChangeHandler}
        placeholder={inputPlaceholder}
      />
      {children || null}
      <button disabled={!write || isLoading || !message}>
        <>{isLoading ? <>{buttonSubmittingText}</> : <>{buttonText}</>}</>
      </button>
      <TransactionResult {...contractWriteResult} />
    </form>
  )
}
