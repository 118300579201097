import React from 'react'
import { useContractReads } from 'wagmi'
import { ReadContractConfig} from '@wagmi/core'

export { useContractReads } from 'wagmi'

type UseContractReads = Parameters<typeof useContractReads>[0]
type UseSingleContractReadsArgs = Omit<UseContractReads, 'contracts'> & {
  contracts: Omit<UseContractReads["contracts"][number], 'addressOrName'|'contractInterface'>[]
  addressOrName: ReadContractConfig["addressOrName"]
  contractInterface: ReadContractConfig["contractInterface"]
}

export function useSingleContractReads({
  contracts,
  ...contractConfig
}: UseSingleContractReadsArgs) {
  return useContractReads({
    contracts: contracts.map((contract) => {
      return {
        ...contractConfig,
        ...contract,
      }
    })
  })
}
