import React from 'react'

type PublicKeyButtonProps = {
  getPublicKey: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>
}

export function PublicKeyButton({ getPublicKey }: PublicKeyButtonProps) {
  return (
    <button onClick={getPublicKey}>Authorize public key</button>
  )
}
