import React from 'react'

const inputStyle: React.CSSProperties = {
  width: '100%',
  padding: '14px 14px',
  margin: '8px 0',
  borderRadius: '4px',
  boxSizing: 'border-box',
}

type InputProps = React.InputHTMLAttributes<HTMLInputElement>

export function Input(props: InputProps) {
  return (
    <input style={inputStyle} {...props} />
  )
}
