import React from 'react'
import debounce from 'lodash.debounce'
import { useGetPublicKey } from '../hooks'
import { RedeemerContext } from '../context'
import {
  EncryptedRequestAction,
  PublicKeyButton,
  Input,
} from '.'

type OpenRequestProps = {
  providerPublicKey: string
}

export function OpenRequest({ providerPublicKey }: OpenRequestProps) {
  const [tokenIds, setTokenIds] = React.useState<string[]>([])
  const [amounts, setAmounts] = React.useState<string[]>([])

  const { redeemer } = React.useContext(RedeemerContext)
  const isErc1155 = redeemer?.tokenType == 'ERC-1155'

  const { publicKey, getPublicKey } = useGetPublicKey()
  const functionCommonArgs = [publicKey, tokenIds]

  const changeHandlerTokenIds = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTokenIds(event.target.value.split(','))
  }

  const debouncedTokenIdsChangeHandler = React.useMemo(
    () => debounce(changeHandlerTokenIds, 300)
  , [])

  const changeHandlerAmounts = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmounts(event.target.value.split(','))
  }

  const debouncedAmountsChangeHandler = React.useMemo(
    () => debounce(changeHandlerAmounts, 300)
  , [])

  return (
    <>
      <h1>Create redemption request</h1>
      {publicKey == null ? (
        <PublicKeyButton getPublicKey={getPublicKey} />
      ) : (
        <EncryptedRequestAction
          args={isErc1155 ? [...functionCommonArgs, amounts] : functionCommonArgs}
          publicKey={providerPublicKey}
          functionName="openRequest"
          receiptFilename="open_request_receipt"
          labelText="Shipping Address"
          ariaLabel="Shipping Address"
          inputPlaceholder="8534 Poor House Street Middletown, CT 06457"
          buttonText="Open"
          buttonSubmittingText="Opening..."
        >
          <label>Phygital Token IDs</label>
          <Input
            aria-label="Phygital Token IDs"
            onChange={debouncedTokenIdsChangeHandler}
            placeholder="1,1337,2000"
          />
          {isErc1155 && (
            <>
              <label>Token Amounts</label>
              <Input
                aria-label="Phygital Token Amounts"
                onChange={debouncedAmountsChangeHandler}
                placeholder="1,2,1"
              />
            </>
          )}
        </EncryptedRequestAction>
      )}
    </>
  )
}
