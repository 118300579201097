import React from 'react'
import { SendTransactionResult } from '@wagmi/core'

type TransactionResultProps = {
  data: SendTransactionResult | undefined
  isSuccess: boolean
  error: Error | null
  isError: boolean
}

export function TransactionResult({
  data,
  isSuccess,
  error,
  isError,
}: TransactionResultProps) {
  return (
    <div>
      {isSuccess && (
        <div>
          Transaction hash: {data?.hash}.
        </div>
      )}
      {(isError) && (
        <div>Error: {(error)?.message}</div>
      )}
    </div>
  )
}
